.footerMain {
    /* background-color: #959595; */
    background-color: #003F66;
    width: 100%;
    padding-top: 60px;
}

.footerblock {
    display: flex;
    flex-direction: row;
}


.footerLogo {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.footerLogo img {
    /* width: 100%; */
    height: 81px;
}

.footerBlockTwo {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerHome,
.footerAboutUs,
.footerService,
.footerContact {

    display: flex;
    flex-direction: column;
    text-align: start;

}


.footerSocialNetwork {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.footerHeadings p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}



.footerList {
    list-style-type: none;
    padding-left: 0px;
    cursor: pointer;
}

.footerList li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 14px;
    color: #DDDDDD;

}

.SocialNetwork {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 25%;
}

.seondFooter{
    
    background-color: #001E2E;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
   
   
}
.seondFootertxt{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;  
}
.seondFootertxt p{
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: #FFFFFF;
padding-top: 10px;
}
.seondFootertxt a {
    font-size: 14px !important;
    color: #FFFFFF;
}






@media (min-width:992px) and (max-width:1100px) {

    .footerLogo {
        width: 20%;
    }

    .footerLogo img {
        width: 170px;
        height: 75px;
    }

    .footerBlockTwo {
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

    }

    .footerSocialNetwork {
        width: 20%;
        display: flex;
        align-items: center;
    }


}
/* mobile */
@media(min-width:320px) and (max-width:767px) {

    .footerblock {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
    }

    .footerBlockTwo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .footerLogo {

        width: 100%;
        display: flex;
        justify-content: center;
    }

    .footerSocialNetwork {
        width: 100%;

    }

    .footerHome,
    .footerAboutUs,
    .footerService,
    .footerContact {

        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .footerList {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SocialNetwork {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 25%;
    }

    .seondFooter{
    
        background-color: #001E2E;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 10px 0px;
        justify-content: space-around;
        align-items: center;
       
       
    }
}


/* tab */
@media (min-width:768px) and (max-width:991px) {
    .footerblock {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }

    .footerLogo {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .footerBlockTwo {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .footerSocialNetwork {
        width: 100%;

    }

    .SocialNetwork {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 40%;
    }


}