.watsappimg{
    height: 3.5vw;
    animation: zoom-in-zoom-out 1s ease infinite;
}
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
}
    50% {
      transform: scale(1.5, 1.5);
      -webkit-transform: scale(1.5, 1.5);
      -moz-transform: scale(1.5, 1.5);
      -ms-transform: scale(1.5, 1.5);
      -o-transform: scale(1.5, 1.5);
}
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
}
  }
.watsappmainDiv{
    position: fixed;
    left:1.5%;
    bottom:5%
}
@media screen and (min-device-width: 1px) and (max-device-width: 767px) {
    .watsappimg{
        height: 10vw;
    }
    .watsappmainDiv{
        position: fixed;
        left:3%;
        bottom:5%
    }
}