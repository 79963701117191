.telephoneMainDiv{
position: fixed;
right: 1.5%;
bottom: 5%;
}
.telephoneImg{
height: 3.5vw;
background: none;
 animation: zoom-in-zoom-out 1s ease infinite;
}
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
@media screen and (min-device-width: 1px) and (max-device-width: 767px) {
    .telephoneImg{
        height: 10vw;
        }
        .telephoneMainDiv{
          position: fixed;
          right: 5%;
          bottom: 5%;
          }
}