body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*  */

@media screen and (max-width: 767px) and (min-width: 100px) {
    .MainDivCanadapr{
        height:70vw !important;
       
    }
    .textOne{
        font-size: 5vw;
    }

    .imgtransDiv {
        height: 30vw;
        width: 40vw;
        background-color: white;
    }
    .cannadaprTextOneSubDiv h1{
        font-size: 5vw !important;
    }
    .cannadaprTextOneDiv p{
        font-size: 3.2vw !important;
    }

    .documenttextMainDiv {
        width: 100%;
    }

    .documentsRequiredDiv {
        width: 100%;
        margin-top: 5%;
        background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%) !important;
    }
    .documentsCardSubdiv span{
        font-size: 3.5vw !important;
    }
    .documentsCardSubdiv p{
        font-size: 2.5vw !important;
    }
    
    .documentsRequiredTextDiv span{
        font-size: 2.3vw !important;
    }
    .documentsRequiredTextDiv p{
        font-size: 2.5vw !important;
    }
    .documentTextwrapTwoDiv{
        margin-top: 10%;
    }

    .officeAddressMainDiv {
        width: 50%;
    }

    .mapImageDiv {
        background-size: 100% 80%;
    }

    .contactUstextdiv span {
        font-size: 5vw;

    }
    .DocReqHead{
        font-size: 4vw !important;
    }
    .tickiconsCls{
        height: 4vw !important;
        width: 4vw !important;
    }
    .mandateText{
        font-size: 2.6vw !important;
    }

    /* contactus */
    .contactusMapmainDiv{
        height: 70vw !important;
    }
    .contactusGirlmainDiv{
        margin-top: 10%;
        height: 90vw !important;
    }
    .contactusCardoneSubDiv{
        /* background-color: red; */
    }
    .callanytime{
        font-size: 2vw;
    }
    .contactusCardTwoDiv{
        width: 50% !important;
        height: 26vw !important;
        /* background-color: yellow !important; */

    }
    .callanytime{
        margin-top: 5%;
        height: 3vw !important;
    }
    .callAnytimeText{
        margin-top: 5%;
        font-size: 3vw !important;
        color: #082A36;
    }

   
}
.appmainDiv {
    height: 100%;
    /* background: linear-gradient(90deg, #E1F3FA 0%, rgba(225, 243, 250, 0.15) 98.83%); */
}
.MainDivCanadapr{
    height: 47vw;
    width: 100%;
    background-image: url(/static/media/Cannada.4a71c8aa.png);
    background-position: center;
    background-size: 100% 100%;
}
.cannadaprTexteTransimg{
    width:90%;
}


.textOne {
    font-style: normal;
    font-weight:700;
    font-size:4.5vw;
    color: #FFFFFF;

}
.imgtransDiv {
    height: 20vw;
    width:30vw;
    background-color:white;
}

.cannadaprTextSecondDiv{
    width: 90%;
    margin-top: 5%;
}
.cannadaprTextOneDiv{
    text-align: left;
    width: 100%;
}
.cannadaprImageTwoDiv{
    width: 100%;
}
.cannadaprTextOneSubDiv h1{
    font-size: 3vw;
    color: #003F66;
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
}
.cannadaprTextOneDiv p{
    font-size: 1.5vw;

}
.formImgSize{
    width: 90%;
}
.DocumentsRequired {
    background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%);
    width: 100%;
    height: 100%;
padding-bottom: 2%;
}
.DocumentReqMabdateDiv{
    width: 90%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin-top: 5%;
}
.tickiconsCls{
    height: 2vw;
    width: 2vw;
}
.mandateText{
    font-size: 1.5vw;
}
.documentsDataMainDIv{
    width: 100%;

}

.documentTextMAinwarp{
    width: 90%;
    /* background-color: #082A36; */
}
.documentTextwraponeDiv{
    width: 100%;
    /* background-color: beige; */
}
.documentTextwrapTwoDiv{
    width: 100%;
    /* background-color: blue; */
}
.documentTextwraponeSubDivone{
    /* background-color: #CBCBCB; */
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}
.documentTextwraponeSubDivtwo{
    width: 100%;
    /* background-color: #082A36; */
    height: 100%;
}
.papperworkimg{
    height: 30vw;
}
.documentsCardOne{
    background: #FFFFFF;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    width: 70%;
    border-radius: 50vw;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
border-radius: 6px;
}
.documentsCardSubdiv{
    width: 90%;
    height: 90%;
    text-align: left;
}
.documentsRequiredTextDiv{
    width: 95%;
    height: 90%;
}
.documentsCardSubdiv span{
    font-size: 2vw;
}
.paperwork{
    font-size: 1.2vw !important;
    white-space: nowrap;
    font-weight: 500;
}
.documentsCardSubdiv p{
    font-size: 1vw;
}

.documentsRequiredTextDiv span{
    font-size: 2vw;
}
.documentsRequiredTextDiv p{
    font-size:1vw;
    margin-bottom: 3px;
    font-weight: 400;
}
.DocReqHead{
    font-size: 3vw;
    color: #003F66;
    font-weight: 600;
}

.DocumentsRequired {
    margin-top: 10%;
}


/* contact us css */

.contactusMainDiv{
    width: 100%;
    position: relative;
}
.contactusTextDiv{
    width: 100%;
    font-size: 3vw;
    font-weight: 500;
    height: 10vw;
    color: #003F66;
}
.contactusSubDiv{
    width:90%;
    /* background-color: black; */
}
.contactusGirlmainDiv{
    width: 100%;
    background-image: url(/static/media/girlimg.7fed1654.png);
    background-size: 100% 100%;
    background-position: center;
    height: 50vw;
}
.contactusMapmainDiv{
    width: 100%;
    background-image: url(/static/media/map.ac5fca38.png);
    background-size: 100% 100%;
    background-position: center;
    height: 40vw;
    margin-left: 2%;
}
.contactusCardsMainDiv{
    position: absolute;
    /* background-color: red; */
    width: 70vw;
    left:10;
    bottom: 0;
}
.contactusCardoneDiv{
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);
}
.contactusCardoneSubDiv a{
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size:  1.171875vw;
    line-height: 1.953125vw;
    color: #0896CC;
    margin-top: 6%;

}
.contactusCardTwoDiv{
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);

}
.contactusCardoneSubDiv{
    width: 90%;
    
}
.callanytime{
    margin-top: 10%;
    height: 3vw;
}
.callAnytimeText{
    margin-top: 10%;
    font-size: 2vw;
    font-family: 'Poppins';
font-style: normal;
}
.callAnytimeparagraph{
    /* margin-top: 10%; */
    font-size: 1.2vw;
    text-align: left;
    margin-bottom: 0%;
    font-family: 'Poppins';
font-style: normal;
}


/* Faq CSS */

.documentsBlocl {
  
    width: 100%;
    background-image: url(/static/media/bg.01b0df8a.png);
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  
  }
  .blockTwoLeft{
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap:2%;
    gap:2%;
    align-items: start;
  }
  
  .Duration {
   width: 80%;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .documentsRequired{
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
  }
  
  
  
  .questionsBlock {
   
    /* border-bottom: solid #0896CC ; */
   
  }
  
  h2 {
    padding-top: 40px;
    padding-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800 !important;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    /* margin-bottom: 30px; */
  }
  
  .tab {
    display: flex;
    justify-content: center;
  }
  
  .tab div {
    display: none;
  
  }
  
  .tab div:target {
    display: block;
  }
  
  .H1B {
   
    border-radius: 10px;
    list-style-type: none;
    width: 50%;
    background: #FFFFFF;
  
  
  }
  
  .tab ul {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    text-align: left;
    color: #343434;
    padding-top: 12px;
  padding-bottom:12px;
  
  }
  
  .tab ul a {
    text-decoration: none;
  }
  
  .tab ul li a:hover {
    color: black;
  }
  
  .tab ul li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .tab p {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-right: 20px;
    
  }
  
  .subPoints li{
    text-align: left !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-top: 5px;
    padding-right: 0px !important;
    
  }
  @media(min-width:1024px) and (max-width:1439px) {
    .tab ul {
      width: 65%;
    }
  
  }
  
  @media(min-width:768px) and (max-width:1023px) {
    .tab ul {
      width: 80%;
    }
  
  }
  
  @media(min-width:320px) and (max-width:767px) {
    .tab ul {
      width: 90%;
    }
  
    h2 {
      padding-top: 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 120%;
      color: #FFFFFF;
      text-align: center;
      white-space: nowrap;
    }
  }
/* .headerMain {
    background-color: white;

} */

/* .logoimg {
    
    height: 70px;
    margin-left: 2%;
} */

/* .navbar-collapse {

    width: 100%;
} */

.nav-link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800 !important;
    font-size: 20px;
    white-space: nowrap;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: #272727 !important;

    /* margin-right: 16px; */

}

.icons {
    border: 1px solid #54CDE7;
    border-radius: 4px;
    padding-top: 15px;
    padding-bottom: 6px;
    box-shadow: 0px 4px 4px 1px rgba(18, 139, 204, 0.2);

    

}
/* .navbar-nav > .active > a {
    background: linear-gradient(199.06deg, #54CDE7 2.74%, #008AE4 95.32%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;

  } */
  /* .nav-item > a:hover {
    background: linear-gradient(199.06deg, #54CDE7 2.74%, #008AE4 95.32%) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
background-clip: text !important;
text-fill-color: transparent !important;

  } */

/* @media (min-width:'1280px') and (max-width:'1440px'){
    .nav-link {
       
        margin-right: 12px;
    
    }

} */







.navigation {
    background-color: #eff1f2;
    /* padding: 2.5925925926vh 0px; */
    /* padding-right: 5vw; */
    /*100px;*/
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
}
/* #navbarSupportedContent {
    transition: height 1.5s ease;
} */
/* .navigation .logo {
    padding-left: 6vw;

} */

.navbtn {
    background: linear-gradient(258deg, #3473b3, #761acf) !important;
}

.hamburger {
    /* background-color: #00151d !important; */
    border: none;
}

.mymenu {

    /* border-color: !important; */
    height: 0.9vw;
    background: linear-gradient(258deg, #3473b3, #761acf) !important;
    width:7vw;
    display: block;
    margin-top:1vw;
    border-radius: 5px;
}
/* .cryptoLogo{
    height: 4vw !important;
    width: 9.8vw !important;
    align-items: center !important;
    float: left;
    
} */

.hamburger span:first-child {
    margin-top: 0px;
}

.logo img {
    /* width: 35px; */
    height: 75px;
    margin-left: 5%;
    /* width: 50%; */
}
.ulist{
    align-items: center;
    text-align: center;
    grid-gap: 7%;
    gap: 7%;
    white-space: nowrap;
}

.navigation li {
    /* margin-right: 3.208333vw; */
    /*100px;*/
    align-self: center;
}

.navigation li a {
    padding: 0px 0px 0px 0px;
    /* font-size: max(0.9375vw, 15px); */
    font-size: 1.3vw;
   color: #272727;
   text-decoration: none;

    
    
}

.navigation li a.active {
    background: linear-gradient(199.06deg, #54CDE7 2.74%, #008AE4 95.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.navigation li:last-child {
    margin-right: 0px;
    /* background: linear-gradient(258deg, #3473b3, #761acf); */
    border-radius: 8px;
    color: #131313;
}

/* .navigation li:last-child a {
    -webkit-text-fill-color: #ffffff;
    color:#ffffff !important;
} */
.navigation li a.active{
    -webkit-text-fill-color: #ffffff;
    /* color: #ffffff !important; */
}

@media screen and (max-width:920px) {
    .navigation li {
        margin-right: 0px;
        margin-top: 10px;
    }
    .navigation li:last-child {
        display: none;
    }

    .navigation li a {
        font-size: 18px;
    }
}


.myitem {
    background-color: red;
}

@media screen and (max-width: 767px)
{
    .cryptoLogo{
        height: 15vw !important;
        width: 40vw !important;
    }
    
}





.watsappimg{
    height: 3.5vw;
    -webkit-animation: zoom-in-zoom-out 1s ease infinite;
            animation: zoom-in-zoom-out 1s ease infinite;
}
@-webkit-keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
}
    50% {
      transform: scale(1.5, 1.5);
      -webkit-transform: scale(1.5, 1.5);
      -moz-transform: scale(1.5, 1.5);
      -ms-transform: scale(1.5, 1.5);
      -o-transform: scale(1.5, 1.5);
}
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
}
  }
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
}
    50% {
      transform: scale(1.5, 1.5);
      -webkit-transform: scale(1.5, 1.5);
      -moz-transform: scale(1.5, 1.5);
      -ms-transform: scale(1.5, 1.5);
      -o-transform: scale(1.5, 1.5);
}
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
}
  }
.watsappmainDiv{
    position: fixed;
    left:1.5%;
    bottom:5%
}
@media screen and (min-device-width: 1px) and (max-device-width: 767px) {
    .watsappimg{
        height: 10vw;
    }
    .watsappmainDiv{
        position: fixed;
        left:3%;
        bottom:5%
    }
}
.telephoneMainDiv{
position: fixed;
right: 1.5%;
bottom: 5%;
}
.telephoneImg{
height: 3.5vw;
background: none;
 -webkit-animation: zoom-in-zoom-out 1s ease infinite;
         animation: zoom-in-zoom-out 1s ease infinite;
}
@-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    50% {
      -webkit-transform: scale(1.5, 1.5);
              transform: scale(1.5, 1.5);
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
@keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    50% {
      -webkit-transform: scale(1.5, 1.5);
              transform: scale(1.5, 1.5);
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
@media screen and (min-device-width: 1px) and (max-device-width: 767px) {
    .telephoneImg{
        height: 10vw;
        }
        .telephoneMainDiv{
          position: fixed;
          right: 5%;
          bottom: 5%;
          }
}
.footerMain {
    /* background-color: #959595; */
    background-color: #003F66;
    width: 100%;
    padding-top: 60px;
}

.footerblock {
    display: flex;
    flex-direction: row;
}


.footerLogo {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.footerLogo img {
    /* width: 100%; */
    height: 81px;
}

.footerBlockTwo {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerHome,
.footerAboutUs,
.footerService,
.footerContact {

    display: flex;
    flex-direction: column;
    text-align: start;

}


.footerSocialNetwork {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.footerHeadings p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}



.footerList {
    list-style-type: none;
    padding-left: 0px;
    cursor: pointer;
}

.footerList li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 14px;
    color: #DDDDDD;

}

.SocialNetwork {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    grid-gap: 25%;
    gap: 25%;
}

.seondFooter{
    
    background-color: #001E2E;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
   
   
}
.seondFootertxt{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;  
}
.seondFootertxt p{
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: #FFFFFF;
padding-top: 10px;
}
.seondFootertxt a {
    font-size: 14px !important;
    color: #FFFFFF;
}






@media (min-width:992px) and (max-width:1100px) {

    .footerLogo {
        width: 20%;
    }

    .footerLogo img {
        width: 170px;
        height: 75px;
    }

    .footerBlockTwo {
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

    }

    .footerSocialNetwork {
        width: 20%;
        display: flex;
        align-items: center;
    }


}
/* mobile */
@media(min-width:320px) and (max-width:767px) {

    .footerblock {
        display: flex;
        flex-direction: column;
        grid-row-gap: 40px;
        row-gap: 40px;
    }

    .footerBlockTwo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-row-gap: 20px;
        row-gap: 20px;
    }

    .footerLogo {

        width: 100%;
        display: flex;
        justify-content: center;
    }

    .footerSocialNetwork {
        width: 100%;

    }

    .footerHome,
    .footerAboutUs,
    .footerService,
    .footerContact {

        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .footerList {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SocialNetwork {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        grid-gap: 25%;
        gap: 25%;
    }

    .seondFooter{
    
        background-color: #001E2E;
        display: flex;
        flex-direction: column;
        grid-row-gap: 10px;
        row-gap: 10px;
        padding: 10px 0px;
        justify-content: space-around;
        align-items: center;
       
       
    }
}


/* tab */
@media (min-width:768px) and (max-width:991px) {
    .footerblock {
        display: flex;
        flex-direction: column;
        grid-row-gap: 50px;
        row-gap: 50px;
    }

    .footerLogo {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .footerBlockTwo {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .footerSocialNetwork {
        width: 100%;

    }

    .SocialNetwork {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        grid-gap: 40%;
        gap: 40%;
    }


}


@media screen and (max-width: 767px) and (min-width: 100px) {
    .MainDivH1BF{
        height:70vw !important;
       
    }
    .H1BFtextOne{                                  
        font-size: 5vw;
    }

    .H1BFimgtransDiv {
        height: 30vw;
        width: 40vw;
        background-color: white;
    }
    .H1BFTextOneSubDiv h1{
        font-size: 5vw !important;
    }
    .H1BFTextOneDiv p{
        font-size: 3.2vw !important;
    }

    .documenttextMainDiv {
        width: 100%;
    }

    .documentsRequiredDiv {
        width: 100%;
        margin-top: 5%;
        background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%) !important;
    }
    .H1BFdocumentsCardSubdiv span{
        font-size: 3.5vw !important;
    }
    .H1BFdocumentsCardSubdiv p{
        font-size: 2.5vw !important;
    }
    
    .H1BFdocumentsRequiredTextDiv span{
        font-size: 3.5vw !important;
    }
    .H1BFdocumentsRequiredTextDiv p{
        font-size: 2.5vw !important;
    }
    .H1BFdocumentTextwrapTwoDiv{
        margin-top: 10%;
    }

    .officeAddressMainDiv {
        width: 50%;
    }

    .mapImageDiv {
        background-size: 100% 80%;
    }

    .contactUstextdiv span {
        font-size: 5vw;

    }
    .H1BFDocReqHead{
        font-size: 4vw !important;
    }
    .tickiconsCls{
        height: 4vw !important;
        width: 4vw !important;
    }
    .mandateText{
        font-size: 2.6vw !important;
    }

    /* contactus */
    .contactusMapmainDiv{
        height: 70vw !important;
    }
    .contactusGirlmainDiv{
        margin-top: 10%;
        height: 90vw !important;
    }
    .contactusCardoneSubDiv{
        /* background-color: red; */
    }
    .callanytime{
        font-size: 2vw;
    }
    .contactusCardTwoDiv{
        width: 50% !important;
        height: 26vw !important;
        /* background-color: yellow !important; */

    }
    .callanytime{
        margin-top: 5%;
        height: 3vw !important;
    }
    .callAnytimeText{
        margin-top: 5%;
        font-size: 3vw !important;
        color: #082A36;
    }

   
}
.H1BFmainDiv {
    height: 100%;
    /* background: linear-gradient(90deg, #E1F3FA 0%, rgba(225, 243, 250, 0.15) 98.83%); */
}
.MainDivH1BF{
    height: 47vw;
    width: 100%;
    background-image: url("/static/media/H1B filling.58c72e4b.png");
    background-position: center;
    background-size: 100% 100%;
}
.H1BFTexteTransimg{
    width:90%;
}


.H1BFtextOne {
    font-style: normal;
    font-weight:700;
    font-size:4.5vw;
    color: #FFFFFF;

}
.H1BFimgtransDiv {
    height: 20vw;
    width:30vw;
    background-color:white;
}

.H1BFTextSecondDiv{
    width: 90%;
    margin-top: 5%;
}
.H1BFTextOneDiv{
    text-align: left;
    width: 100%;
}
.H1BFImageTwoDiv{
    width: 100%;
}
.H1BFTextOneSubDiv h1{
    font-size: 3vw;
    color: #003F66;
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
}
.H1BFTextOneDiv p{
    font-size: 1.3vw;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;

}
.H1BFformImgSize{
    width: 90%;
}
.H1BFDocumentsRequired {
    background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%);
    width: 100%;
    height: 100%;
padding-bottom: 2%;
}
.H1BFDocumentReqMabdateDiv{
    width: 90%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin-top: 5%;
}
.tickiconsCls{
    height: 2vw;
    width: 2vw;
}
.mandateText{
    font-size: 1.5vw;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
}
.H1BFdocumentsDataMainDIv{
    width: 100%;

}

.H1BFdocumentTextMAinwarp{
    width: 90%;
    /* background-color: #082A36; */
}
.H1BFdocumentTextwraponeDiv{
    width: 100%;
    /* background-color: beige; */
}
.H1BFdocumentTextwrapTwoDiv{
    width: 100%;
    /* background-color: blue; */
}
.H1BFdocumentTextwraponeSubDivone{
    /* background-color: #CBCBCB; */
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}
.H1BFdocumentTextwraponeSubDivtwo{
    width: 100%;
    /* background-color: #082A36; */
    height: 100%;
}
.papperworkimg{
    height: 30vw;
}
.H1BFdocumentsCardOne{
    background: #FFFFFF;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    width: 100%;
    border-radius: 50vw;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
border-radius: 6px;
}
.H1BFdocumentsCardSubdiv{
    width: 90%;
    height: 90%;
    text-align: left;
}
.H1BFdocumentsRequiredTextDiv{
    width: 110%;
    height: 90%;
}
.H1BFdocumentsCardSubdiv span{
    font-size: 1.8vw;
    white-space: nowrap;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
}
.paperwork{
    font-size: 1.2vw !important;
    white-space: nowrap;
    font-weight: 500;
}
.H1BFdocumentsCardSubdiv p{
    font-size: 1vw;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
}

.H1BFdocumentsRequiredTextDiv span{
    font-size: 1.8vw;
    font-family: 'Poppins';
font-style: normal;
white-space: nowrap;
font-weight: 400;
}
.H1BFdocumentsRequiredTextDiv p{
    font-size:1vw;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start;
    margin-left: 5px;
}
.H1BFDocReqHead{
    font-size: 3vw;
    color: #003F66;
    font-weight: 600;
    font-family: 'Poppins';
font-style: normal;

}

.H1BFDocumentsRequired {
    /* margin-top: 5%; */
}


/* contact us css */

.contactusMainDiv{
    width: 100%;
    position: relative;
    /* padding-bottom: 5% !important; */
}
.contactusTextDiv{
    width: 100%;
    font-size: 3vw;
    font-weight: 600;
    height: 10vw;
    color: #003F66;
    font-family: 'Poppins';
font-style: normal;

}
.contactusSubDiv{
    width:90%;
    /* background-color: black; */
}
.contactusGirlmainDiv{
    width: 100%;
    background-image: url(/static/media/girlimg.7fed1654.png);
    background-size: 100% 100%;
    background-position: center;
    height: 50vw;
}
.contactusMapmainDiv{
    width: 100%;
    background-image: url(/static/media/map.ac5fca38.png);
    background-size: 100% 80%;
    background-position: center;
    height: 40vw;
    margin-left: 2%;
}
.contactusCardsMainDiv{
    position: absolute;
    /* background-color: red; */
    width: 70vw;
    left:10;
    bottom: 0;
    padding-bottom: 5% !important;
}
.contactusCardoneDiv{
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);
}
.contactusCardoneSubDiv a{
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size:  1.171875vw;
    line-height: 1.953125vw;
    color: #0896CC;
    margin-top: 6%;

}
.contactusCardTwoDiv{
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);

}
.contactusCardoneSubDiv{
    width: 90%;
    
}
.callanytime{
    margin-top: 10%;
    height: 3vw;
}
.callAnytimeText{
    margin-top: 10%;
    font-size: 2vw;
    font-family: 'Poppins';
font-style: normal;
}
.callAnytimeparagraph{
    /* margin-top: 10%; */
    font-size: 1.2vw;
    text-align: left;
    margin-bottom: 0%;
    font-family: 'Poppins';
font-style: normal;
}


/* Faq CSS */

.documentsBlocl {
    width: 100%;
    background-image: url(/static/media/bg.01b0df8a.png);
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
  
  }
  .blockTwoLeft{
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap:2%;
    gap:2%;
    align-items: start;
  }
  
  .Duration {
   width: 80%;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .documentsRequired{
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
  }
  
  
  
  .questionsBlock {
   
    /* border-bottom: solid #0896CC ; */
   
  }
  
  h2 {
    padding-top: 40px;
    padding-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800 !important;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    /* margin-bottom: 30px; */
  }
  
  .tab {
    display: flex;
    justify-content: center;
  }
  
  .tab div {
    display: none;
  
  }
  
  .tab div:target {
    display: block;
  }
  
  .H1B {
   
    border-radius: 10px;
    list-style-type: none;
    width: 50%;
    background: #FFFFFF;
  
  
  }
  
  .tab ul {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    text-align: left;
    color: #343434;
    padding-top: 12px;
  padding-bottom:12px;
  
  }
  
  .tab ul a {
    text-decoration: none;
  }
  
  .tab ul li a:hover {
    color: black;
  }
  
  .tab ul li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .tab p {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-right: 20px;
    
  }
  
  .subPoints li{
    text-align: left !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-top: 5px;
    padding-right: 0px !important;
    
  }
  @media(min-width:1024px) and (max-width:1439px) {
    .tab ul {
      width: 65%;
    }
  
  }
  
  @media(min-width:768px) and (max-width:1023px) {
    .tab ul {
      width: 80%;
    }
  
  }
  
  @media(min-width:320px) and (max-width:767px) {
    .tab ul {
      width: 90%;
    }
  
    h2 {
      padding-top: 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 25px;
      line-height: 120%;
      color: #FFFFFF;
      text-align: center;
      white-space: nowrap;
    }
  }


@media screen and (max-width: 767px) and (min-width: 100px) {
    .MainDivH1BT{
        height:70vw !important;
       
    }
    .H1BTtextOne{                                  
        font-size: 5vw;
    }

    .H1BTimgtransDiv {
        height: 30vw;
        width: 40vw;
        background-color: white;
    }
    .H1BTTextOneSubDiv h1{
        font-size: 5vw !important;
    }
    .H1BTTextOneDiv p{
        font-size: 3.2vw !important;
    }

    .documenttextMainDiv {
        width: 100%;
    }

    .documentsRequiredDiv {
        width: 100%;
        margin-top: 5%;
        background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%) !important;
    }
    .H1BTdocumentsCardSubdiv span{
        font-size: 3.5vw !important;
    }
    .H1BTdocumentsCardSubdiv p{
        font-size: 2.5vw !important;
        margin-bottom: 0%;
    }
    
    .H1BTdocumentsRequiredTextDiv span{
        font-size: 3.5vw !important;
    }
    .H1BTdocumentsRequiredTextDiv p{
        font-size: 2.5vw !important;
    }
    .H1BTdocumentTextwrapTwoDiv{
        margin-top: 10%;
    }

    .officeAddressMainDiv {
        width: 50%;
    }

    .mapImageDiv {
        background-size: 100% 80%;
    }

    .contactUstextdiv span {
        font-size: 5vw;

    }
    .H1BTDocReqHead{
        font-size: 4vw !important;
    }
    .tickiconsCls{
        height: 4vw !important;
        width: 4vw !important;
    }
    .mandateText{
        font-size: 2.6vw !important;
    }

    /* contactus */
    .contactusMapmainDiv{
        height: 70vw !important;
    }
    .contactusGirlmainDiv{
        margin-top: 10%;
        height: 90vw !important;
    }
  
    .callanytime{
        font-size: 2vw;
    }
    .contactusCardTwoDiv{
        width: 50% !important;
        height: 26vw !important;
        /* background-color: yellow !important; */

    }
    .callAnytimeparagraph{
        margin-top: 0% !important;
    }
    .callanytime{
        margin-top: 5%;
        height: 3vw !important;
    }
    .callAnytimeText{
        margin-top: 5%;
        font-size: 3vw !important;
    }

   
}
.H1BTmainDiv {
    height: 100%;
    /* background: linear-gradient(90deg, #E1F3FA 0%, rgba(225, 243, 250, 0.15) 98.83%); */
}
.MainDivH1BT{
    height: 47vw;
    width: 100%;
    background-image: url(/static/media/h1btransfer.6b6d22a6.png);
    background-position: center;
    background-size: 100% 100%;
}
.H1BTTexteTransimg{
    width:90%;
}


.H1BTtextOne {
    font-style: normal;
    font-weight:700;
    font-size:4.5vw;
    color: #FFFFFF;

}
.H1BTimgtransDiv {
    height: 20vw;
    width:30vw;
    background-color:white;
}

.H1BTTextSecondDiv{
    width: 90%;
    margin-top: 5%;
}
.H1BTTextOneDiv{
    text-align: left;
    width: 100%;
}
.H1BTImageTwoDiv{
    width: 100%;
}
.H1BTTextOneSubDiv h1{
    font-size: 3vw;
    color: #003F66;
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
}
.H1BTTextOneDiv p{
    font-size: 1.3vw;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;

}
.H1BTformImgSize{
    width: 90%;
}
.H1BTDocumentsRequired {
    background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%);
    width: 100%;
    height: 100%;
padding-bottom: 2%;
}
.H1BTDocumentReqMabdateDiv{
    width: 90%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin-top: 5%;
    text-align: start;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
}
.tickiconsCls{
    height: 2vw;
    width: 2vw;
}
.mandateText{
    font-size: 1.5vw;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
}
.H1BTdocumentsDataMainDIv{
    width: 100%;

}

.H1BTdocumentTextMAinwarp{
    width: 90%;
    /* background-color: #082A36; */
}
.H1BTdocumentTextwraponeDiv{
    width: 100%;
    /* background-color: beige; */
}
.H1BTdocumentTextwrapTwoDiv{
    width: 100%;
    /* background-color: blue; */
}
.H1BTdocumentTextwraponeSubDivone{
    /* background-color: #CBCBCB; */
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}
.H1BTdocumentTextwraponeSubDivtwo{
    width: 100%;
    /* background-color: #082A36; */
    height: 100%;
}
.papperworkimg{
    height: 30vw;
}
.H1BTdocumentsCardOne{
    background: #FFFFFF;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    width: 100%;
    border-radius: 50vw;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
border-radius: 6px;
}
.H1BTdocumentsCardSubdiv{
    width: 90%;
    height: 90%;
    text-align: left;
}
.H1BTdocumentsRequiredTextDiv{
    width: 110%;
    height: 90%;
}
.H1BTdocumentsCardSubdiv span{
    font-size: 1.8vw;
    white-space: nowrap;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
text-align: start !important;
}
.paperwork{
    font-size: 1.2vw !important;
    white-space: nowrap;
    font-weight: 500;
}
.H1BTdocumentsCardSubdiv p{
    font-size: 1vw;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
text-align: start !important;
}

.H1BTdocumentsRequiredTextDiv span{
    font-size: 1.8vw;
    font-family: 'Poppins';
font-style: normal;
white-space: nowrap;
font-weight: 400;
}
.H1BTdocumentsRequiredTextDiv p{
    font-size:1vw;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start;
    margin-left: 5px;
}
.H1BTDocReqHead{
    font-size: 3vw;
    color: #003F66;
    font-weight: 600;
    font-family: 'Poppins';
font-style: normal;

}

.H1BTDocumentsRequired {
    margin-top: 5%;
}


/* contact us css */

.contactusMainDiv{
    width: 100%;
    position: relative;
}
.contactusTextDiv{
    width: 100%;
    font-size: 3vw;
    font-weight: 600;
    height: 10vw;
    color: #003F66;
    font-family: 'Poppins';
font-style: normal;

}
.contactusSubDiv{
    width:90%;
    /* background-color: black; */
}
.contactusGirlmainDiv{
    width: 100%;
    background-image: url(/static/media/girlimg.7fed1654.png);
    background-size: 100% 100%;
    background-position: center;
    height: 50vw;
}
.contactusMapmainDiv{
    width: 100%;
    height: 40vw;
    margin-left: 2%;
}
.contactusCardsMainDiv{
    position: absolute;
    /* background-color: red; */
    width: 70vw;
    left:10;
    bottom: 0;
}
.contactusCardoneDiv{
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);
}
.contactusCardoneSubDiv a{
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size:  1.171875vw;
    line-height: 1.953125vw;
    color: #0896CC;
    margin-top: 6%;

}
.contactusCardTwoDiv{
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);

}
.contactusCardoneSubDiv{
    width: 90%;
    
}
.callanytime{
    margin-top: 10%;
    height: 3vw;
}
.callAnytimeText{
    margin-top: 10%;
    font-size: 2vw;
    font-family: 'Poppins';
font-style: normal;
color: #082A36;
}
.callAnytimeparagraph{
    /* margin-top: 10%; */
    font-size: 1.2vw;
    text-align: left;
    margin-bottom: 0%;
    font-family: 'Poppins';
font-style: normal;
}


/* Faq CSS */

.documentsBlocl {
    width: 100%;
    background-image: url(/static/media/bg.01b0df8a.png);
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
  
  }
  .blockTwoLeft{
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap:2%;
    gap:2%;
    align-items: start;
  }
  
  .Duration {
   width: 80%;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .documentsRequired{
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
  }
  
  
  
  .questionsBlock {
   
    /* border-bottom: solid #0896CC ; */
   
  }
  
  h2 {
    padding-top: 40px;
    padding-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800 !important;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    /* margin-bottom: 30px; */
  }
  
  .tab {
    display: flex;
    justify-content: center;
  }
  
  .tab div {
    display: none;
  
  }
  
  .tab div:target {
    display: block;
  }
  
  .H1B {
   
    border-radius: 10px;
    list-style-type: none;
    width: 50%;
    background: #FFFFFF;
  
  
  }
  
  .tab ul {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    text-align: left;
    color: #343434;
    padding-top: 12px;
  padding-bottom:12px;
  
  }
  
  .tab ul a {
    text-decoration: none;
  }
  
  .tab ul li a:hover {
    color: black;
  }
  
  .tab ul li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .tab p {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-right: 20px;
    
  }
  
  .subPoints li{
    text-align: left !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-top: 5px;
    padding-right: 0px !important;
    
  }
  @media(min-width:1024px) and (max-width:1439px) {
    .tab ul {
      width: 65%;
    }
  
  }
  
  @media(min-width:768px) and (max-width:1023px) {
    .tab ul {
      width: 80%;
    }
  
  }
  
  @media(min-width:320px) and (max-width:767px) {
    .tab ul {
      width: 90%;
    }
  
    h2 {
      padding-top: 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 120%;
      color: #FFFFFF;
      text-align: center;
      white-space: nowrap;
    }
  }
@media screen and (max-width: 767px) and (min-width: 100px) {
    .MainDivUKW {
        height: 70vw !important;

    }

    .UKWtextOne {
        font-size: 5vw;
    }

    .UKWimgtransDiv {
        height: 30vw;
        width: 40vw;
        background-color: white;
    }

    .UKWTextOneSubDiv h1 {
        font-size: 5vw !important;
    }

    .UKWTextOneDiv p {
        font-size: 3.2vw !important;
    }

    .documenttextMainDiv {
        width: 100%;
    }

    .documentsRequiredDiv {
        width: 100%;
        margin-top: 5%;
        background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%) !important;
    }

    .UKWdocumentsCardSubdiv span {
        font-size: 3.5vw !important;
    }

    .UKWdocumentsCardSubdiv p {
        font-size: 2.5vw !important;
        margin-bottom: 0%;
    }

    .UKWdocumentsRequiredTextDiv span {
        font-size: 3.5vw !important;
    }

    .UKWdocumentsRequiredTextDiv p {
        font-size: 2.5vw !important;
    }

    .UKWdocumentTextwrapTwoDiv {
        margin-top: 10%;
    }

    .officeAddressMainDiv {
        width: 50%;
    }

    .mapImageDiv {
        background-size: 100% 80%;
    }

    .contactUstextdiv span {
        font-size: 5vw;

    }

    .UKWDocReqHead {
        font-size: 4vw !important;
    }

    .tickiconsCls {
        height: 4vw !important;
        width: 4vw !important;
    }

    .mandateText {
        font-size: 2.6vw !important;
    }

    /* contactus */
    .contactusMapmainDiv {
        height: 70vw !important;
    }

    .contactusGirlmainDiv {
        margin-top: 10%;
        height: 90vw !important;
    }

    .callanytime {
        font-size: 2vw;
    }

    .contactusCardTwoDiv {
        width: 50% !important;
        height: 26vw !important;
        /* background-color: yellow !important; */

    }

    .callAnytimeparagraph {
        margin-top: 0% !important;
    }

    .callanytime {
        margin-top: 5%;
        height: 3vw !important;
    }

    .callAnytimeText {
        margin-top: 5%;
        font-size: 3vw !important;
    }


}

.UKWmainDiv {
    height: 100%;
    /* background: linear-gradient(90deg, #E1F3FA 0%, rgba(225, 243, 250, 0.15) 98.83%); */
}

.MainDivUKW {
    height: 47vw;
    width: 100%;
    background-image: url(/static/media/UKwork.3f28bfcd.png);
    background-position: center;
    background-size: 100% 100%;
}

.UKWTexteTransimg {
    width: 90%;
}


.UKWtextOne {
    font-style: normal;
    font-weight: 700;
    font-size: 4.5vw;
    color: #FFFFFF;

}

.UKWimgtransDiv {
    height: 20vw;
    width: 30vw;
    background-color: white;
}

.UKWTextSecondDiv {
    width: 90%;
    margin-top: 5%;
}

.UKWTextOneDiv {
    text-align: left;
    width: 100%;
}

.UKWImageTwoDiv {
    width: 100%;
}

.UKWTextOneSubDiv h1 {
    font-size: 3vw;
    color: #003F66;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
}

.UKWTextOneDiv p {
    font-size: 1.3vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;

}

.UKWformImgSize {
    width: 90%;
}

.UKWDocumentsRequired {
    background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%);
    width: 100%;
    height: 100%;
    padding-bottom: 2%;
}

.UKWDocumentReqMabdateDiv {
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 5%;
    text-align: start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.tickiconsCls {
    height: 2vw;
    width: 2vw;
}

.mandateText {
    font-size: 1.5vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.UKWdocumentsDataMainDIv {
    width: 100%;

}

.UKWdocumentTextMAinwarp {
    width: 90%;
    /* background-color: #082A36; */
}

.UKWdocumentTextwraponeDiv {
    width: 100%;
    /* background-color: beige; */
}

.UKWdocumentTextwrapTwoDiv {
    width: 100%;
    /* background-color: blue; */
}

.UKWdocumentTextwraponeSubDivone {
    /* background-color: #CBCBCB; */
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.UKWdocumentTextwraponeSubDivtwo {
    width: 100%;
    /* background-color: #082A36; */
    height: 100%;
}

.papperworkimg {
    height: 30vw;
}

.UKWdocumentsCardOne {
    background: #FFFFFF;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 80%;
    border-radius: 50vw;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    border-radius: 6px;
}

.UKWdocumentsCardSubdiv {
    width: 90%;
    height: 90%;
    text-align: left;
}

.UKWdocumentsRequiredTextDiv {
    width: 110%;
    height: 90%;
}

.UKWdocumentsCardSubdiv span {
    font-size: 1.8vw;
    /* white-space: nowrap; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start !important;
}

.paperwork {
    font-size: 1.2vw !important;
    white-space: nowrap;
    font-weight: 500;
}

.UKWdocumentsCardSubdiv p {
    font-size: 1vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start !important;
}

.UKWdocumentsRequiredTextDiv span {
    font-size: 1.8vw;
    font-family: 'Poppins';
    font-style: normal;
    white-space: nowrap;
    font-weight: 400;
}

.UKWdocumentsRequiredTextDiv p {
    font-size: 1vw;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start;
    margin-left: 5px;
}

.UKWDocReqHead {
    font-size: 3vw;
    color: #003F66;
    font-weight: 600;
    font-family: 'Poppins';
    font-style: normal;

}

.UKWDocumentsRequired {
    margin-top: 5%;
}


/* contact us css */

.contactusMainDiv {
    width: 100%;
    position: relative;
}

.contactusTextDiv {
    width: 100%;
    font-size: 3vw;
    font-weight: 600;
    height: 10vw;
    color: #003F66;
    font-family: 'Poppins';
    font-style: normal;

}

.contactusSubDiv {
    width: 90%;
    /* background-color: black; */
}

.contactusGirlmainDiv {
    width: 100%;
    background-image: url(/static/media/girlimg.7fed1654.png);
    background-size: 100% 100%;
    background-position: center;
    height: 50vw;
}

.contactusMapmainDiv{
    width: 100%;
    height: 40vw;
    margin-left: 2%;
}

.contactusCardsMainDiv {
    position: absolute;
    /* background-color: red; */
    width: 70vw;
    left: 10;
    bottom: 0;
}

.contactusCardoneDiv {
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);
}

.contactusCardoneSubDiv a {
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.171875vw;
    line-height: 1.953125vw;
    color: #0896CC;
    margin-top: 6%;

}

.contactusCardTwoDiv {
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);

}

.contactusCardoneSubDiv {
    width: 90%;

}

.callanytime {
    margin-top: 10%;
    height: 3vw;
}

.callAnytimeText {
    /* margin-top: 10%; */
    font-size: 2vw;
    font-family: 'Poppins';
    font-style: normal;
    color: #082A36 !important;
}

.callAnytimeparagraph {
    /* margin-top: 10%; */
    font-size: 1.2vw;
    text-align: left;
    margin-bottom: 0%;
    font-family: 'Poppins';
    font-style: normal;
}


/* Faq CSS */

.documentsBlocl {

    width: 100%;
    background-image: url(/static/media/bg.01b0df8a.png);
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    


}

.blockTwoLeft {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 2%;
    gap: 2%;
    align-items: start;
}

.Duration {
    width: 80%;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.documentsRequired {
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
}



.questionsBlock {

    /* border-bottom: solid #0896CC; */

}

h2 {
    padding-top: 40px;
    padding-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800 !important;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    /* margin-bottom: 30px; */
}

.tab {
    display: flex;
    justify-content: center;
}

.tab div {
    display: none;

}

.tab div:target {
    display: block;
}

.H1B {

    border-radius: 10px;
    list-style-type: none;
    width: 50%;
    background: #FFFFFF;


}

.tab ul {
    padding-top: 8px;
    padding-bottom: 8px;
}

.question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    text-align: left;
    color: #343434;
    padding-top: 12px;
    padding-bottom: 12px;

}

.tab ul a {
    text-decoration: none;
}

.tab ul li a:hover {
    color: black;
}

.tab ul li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tab p {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-right: 20px;

}

.subPoints li {
    text-align: left !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-top: 5px;
    padding-right: 0px !important;

}

@media(min-width:1024px) and (max-width:1439px) {
    .tab ul {
        width: 65%;
    }

}

@media(min-width:768px) and (max-width:1023px) {
    .tab ul {
        width: 80%;
    }

}

@media(min-width:320px) and (max-width:767px) {
    .tab ul {
        width: 90%;
    }

    h2 {
        padding-top: 30px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 120%;
        color: #FFFFFF;
        text-align: center;
        white-space: nowrap;
    }
}
* {
  font-family: 'Poppins';
}

.lokahhoMainDiv {
  width: 100%;
  background-image: url(/static/media/Bubble.29e8c31c.png), url(/static/media/Bubble.29e8c31c.png);
  background-position: top left;
  background-size: 50vw 40vw, 49vw 39vw;
  background-repeat: no-repeat;
}

.lokahhomefirstDiv {
  width: 100%;
  height: 20vw;
  
}
.mainbg{
  width: 100%;
  background: linear-gradient(90deg, #9BD3E8 0%, rgba(141, 202, 225, 0.3) 98.83%); 
}

.lokahhomeSubdiv {
  width: 90%;
  
}

.lbold {
  font-weight: 800 !important;
}

.headingTexts {
  width: 99%;
  text-align: start;
}

.lokahfirstImgDiv {
  width: 100%;
}

.mainimg {
  width: 45vw;
  height: 50vw;
  margin-top: 5vw;
}

.startYourJourney {
  font-size: 3vw;
  font-weight: 600;
  width: 100%;
  padding-top: 8vw;
  padding-bottom: 1vw;
  color: #1F2C51;
  text-align: start;
}

.morethanDiv {
  width: 100%;
  height: 100%;
  font-size: 1.2VW;
  letter-spacing: 1;
}

.lokahDiscription {
  width: 70%;
  font-size: 1.5vw;
  margin-top: 2%;
  text-align: start;
  color: #003F66 !important;
}

.homeAchivementsMainDiv {
  width: 100%;
}

.homeAchivementsSubDiv {
  width: 88.9%;
}

.achivementsText {
  font-size: 1.7vw;
  padding-top: 3vw;
  padding-bottom: 2vw;
  color: #003F66;

}

.MethodologyText {
  font-size: 2vw;
  font-weight: 600;
  color: #003F66;
  padding-bottom: 2vw;
}

.achivementDescriptionDiv {
  width: 100%;
  font-size: 1.4vw;
  text-align: start;
}

.stepOneDiv {
  width: 100%;
}

.steptwoDiv {
  width: 100%;
}

.steponeImg {
  height: 25vw;
  width: 43.5vw;
}

.steptwoImg {
  height: 23vw;
  width: 42vw;
  margin-top: 2.9%;
}

.stepsMainDiv {
  padding-top: 3vw;
  padding-bottom: 3vw;
  width: 100%;
}

.meetLokahMaindiv {
  width: 100%;
}

.meetlokahTextDiv {
  width: 90%;
  text-align: center;
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: 2.5vw;
  font-weight: 600;
  color: rgba(0, 63, 102, 1);
}

.meetlokahFlexDiv {
  width: 100%;
}

.meetLokahSubDivOne {
  width: 100%;
}

.meetLokahSubDivTwo {
  width: 100%;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.meetLokahSubDivOneChildDiv {
  width: 80%;
}

.theworldLokah {
  font-size: 2vw;
  font-weight: 700;
  padding-top: 5vw;
  color: #003F66;
}

.theWordLokahDescription {
  width: 95%;
  font-size: 1.2vw;
  padding-top: 2vw;
  letter-spacing: 1px;
  text-align: start;
}

.meetuplokahImg {
  height: 26vw;
  width: 40vw;
}

.ourServiceMainDiv {
  width: 100%;
  background: linear-gradient(90deg, #9BD3E8 0%, rgba(141, 202, 225, 0.3) 98.83%);
  background-size: 100% 65vw;
  background-repeat: no-repeat;
}

.ourServiceSubDiv {
  width: 90%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 2vw;
  padding-bottom: 2vw;
  text-align: center;
  font-size: 2vw;
  font-weight: 600;
  color: #003F66;
}

.homeCardsDiv {
  width: 80%;
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.cardsDivHomepage {
  background-color: #FFFFFF;
  height: 30vw;
  width: 22vw;
  box-shadow: 0px 20px 40px rgba(8, 150, 204, 0.2);
  border-radius: 0.3vw;
  text-align: start;
  grid-gap: 3%;
  gap: 3%;
  align-items: center;
}

.homecardImgOneDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url(/static/media/rocketLaunch.0103fff6.png);
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
  align-items: center;
}

.homecardImgTwoDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url(/static/media/closeupBusiness.ede86590.png);
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homecardImgThreeDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url(/static/media/highwayView.981fc8d6.png);
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homecardImgFourDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url(/static/media/UKwork.3f28bfcd.png);
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homecardImgFiveDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url(/static/media/student.f493dae0.png);
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homeCardsSecondDiv {
  width: 60%;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.cardsTextDiv {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.7vw;
  line-height: 125%;
  text-align: start;
  color: #232323;
}

.cardParaDiv {
  font-family: 'Poppins';
  font-style: normal;
  /* font-weight: 200; */
  font-size: 18px;
  line-height: 22px;
  text-align: start;
  color: #232323;
}

.cardButtonDiv button {
  border: none;
  outline: none;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  background-color: white;
  color: #424242;

}

.cardstextmaindiv {
  width: 80%;
}

.cardsTextDiv {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.7vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  text-align: start;
  color: #232323;
}

.cardParaDiv {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-size: 1vw;
  line-height: 1.5vw;
  text-align: start;
  color: #232323;
}

.getstarted {
  font-size: 1.2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.getstarted button {
  border: none;
  background-color: #FFFFFF;
}

.GsBtn span {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

  color: #424242;
}

@media screen and (min-device-width: 1px) and (max-device-width: 767px) {
  .lokahhoMainDiv {
    width: 100%;
    background-image: url(/static/media/Bubble.29e8c31c.png), url(/static/media/Bubble.29e8c31c.png);
    background-position: top left;
    background-size: 85vw 65vw, 83vw 63vw;
    background-repeat: no-repeat;
  }

  .lokahhomefirstDiv {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 3vw;
  }

  .startYourJourney {
    font-size: 5vw;
    width: 100%;
    /* padding-top: 20vw; */
  }

  .morethanDiv {
    font-size: 2.3vw;

  }

  .lokahDiscription {
    font-size: 2.7vw;
    width: 40%;
    white-space: nowrap;

  }

  .lokahDiscription p {
    margin-bottom: 3% !important;
  }

  .mainimg {
    width: 65vw;
    height: 70vw;
    margin-top: 0%;
  }

  .achivementsText {
    font-size: 2.7vw;
  }

  .MethodologyText {
    font-size: 3vw;
  }

  .achivementDescriptionDiv {
    font-size: 2.4vw;
    text-align: start;
  }

  .steponeImg {
    height: 45vw;
    width: 68.5vw;
  }

  .steptwoImg {
    height: 30vw;
    width: 50vw;
    margin-top: 0;
  }

  .meetlokahTextDiv {
    font-size: 3.5vw;
  }

  .theWordLokahDescription {
    font-size: 2.5vw;
  }

  .theworldLokah {
    font-size: 3vw;
  }

  .meetuplokahImg {
    height: 50vw;
    width: 85vw;
  }

  .homeCardsDiv {
    width: 90%;
  }

  .cardsDivHomepage {
    margin-top: 10%;
    width: 90vw;
    height: 130vw;
    border-radius: 1.5vw;
    grid-gap: 3%;
    gap: 3%;

  }

  .homecardImgOneDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgTwoDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgThreeDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgFourDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgFiveDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .ourServiceSubDiv {
    font-size: 4.5vw;
  }

  .cardsTextDiv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 7vw;
    line-height: 125%;
    text-align: start;
    color: #232323;
  }

  .cardParaDiv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 5vw;
    line-height: 20px;
    text-align: start;
    /* margin-left: 5%; */
    color: #232323;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .Testimonialspassageone {
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 10px !important;
    Line-height: 15px !important;
    color: #3B4667;

  }

  .TestimonialspassageTwo {
    Font-family: Poppins;
    Font-style: Regular;
    text-align: center !important;
    /* padding-top: 25px; */
    Font-size: 15px !important;
    Line-height: 40px;
    color: #1F2C51;
    /* padding-bottom: 10px; */
    /* padding-left: 10px; */
    white-space: nowrap;
  }

  .ourServiceMainDiv {
    background-size: 100% 91%;
  }

  .cardsTextDiv {
    font-size: 6vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }

  .cardParaDiv {
    font-size: 3.5vw;
    line-height: 6vw;
    text-align: left;
  }

  .getstarted {
    font-size: 4vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
  }
}

/* whyLokahBlockStart */
.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.lokahBlock {
  margin-bottom: 60px;
}

.whyLokahBlock {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 4%;
  grid-column-gap: 4%;
  -webkit-column-gap: 4%;
          column-gap: 4%;
  padding-right: 4%;
  /* justify-content: space-evenly; */


}

.lokahBlockimg {
  height: 26vw;
  width: 40vw;

}

.lokahBlock h1 {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 40px;
  Font-family: Poppins;
  Font-style: SemiBold;
  Font-size: 48px;
  Line-height: 60px;
  color: #003F66;
}

.whyLokahRightBlock {
  /* padding-top: 30px; */
  Font-family: Poppins;
  Font-style: Regular;
  Font-size: 18px;
  Line-height: 40px;
  color: #232323;
  text-align: start;
}

.lokahBlockPassage {
  padding-top: 40px;
  padding-left: 4%;
  padding-right: 4%;
  text-align: justify;
}

.lokahBlockPassageone {
  Font-family: Poppins;
  Font-style: Regular;
  Font-size: 18px;
  Line-height: 30px;
}

@media(min-width:1024px) and (max-width:1170px) {
  .lokahBlockimg {
    height: 30vw;
    width: 46vw;
  }

  .whyLokahRightBlock {
    padding-top: 20px;
  }
}

@media(min-width:920px) and (max-width:1023px) {
  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 16px;
    Line-height: 28px;
    color: #232323;
  }
}

@media(min-width:768px) and (max-width:920px) {

  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 15px;
    Line-height: 25px;
    color: #232323;
  }

}

@media(min-width:424px) and (max-width:767px) {

  .whyLokahBlock {

    display: flex;
    flex-direction: column;
    /* align-items: ; */
    /* padding-left: 4%; */
    /* row-gap: 4%; */
    /* padding-right: 4%; */
  }

  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 20px;
    Line-height: 40px;
    color: #232323;
  }

  .lokahBlockimg {
    height: 50vw;
    width: 90vw;
    /* margin-bottom: 25px; */
  }
}

@media(min-width:320px) and (max-width:426px) {

  .lokahBlockrevrse {
    display: flex;
    flex-direction: column-reverse;
  }

  .lokahBlock h1 {
    padding-bottom: 0px;
  }

  .whyLokahBlock {

    display: flex;
    flex-direction: column;
    /* padding-top: 20px; */
    /* align-items: ; */
    padding-left: 6%;
    /* row-gap: 4%; */
    padding-right: 6%;
  }

  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 10px;
    Line-height: 25px;
    color: #232323;
    padding-left: 6%;
    padding-right: 6%;


  }

  .lokahBlockimg {
    height: 50vw;
    width: 90vw;
    /* margin-bottom: 25px; */
    padding-right: 4%;
  }

  .lokahBlockPassageone {
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 10px;
    Line-height: 20px;
  }

  .lokahBlockPassage {
    padding-top: 8px;
    padding-left: 8%;
    padding-right: 8%;
    text-align: justify;

  }
}

.TestimonialsBlock {
  display: flex;
  /* flex-direction: column; */
}

.Testimonials {
  background: rgba(155, 211, 232, 0.3);
  padding-bottom: 60px;
}


.TestimonialsLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

/* .TestimonialsLefticon{
    width:80px;
  }
  .TestimonialsRighticon{
    width:40px;
  } */
.TestimonialsRight {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TestimonialsHeading {
  display: flex;
  justify-content: center;
  Font-family: Poppins;
  Font-style: SemiBold;
  Font-size: 48px;
  Line-height: 60px;
  color: #003F66;
  padding-bottom: 60px;
  padding-top: 40px;
}

.Testimonialspassage {
  width: 60%;
  /* height: 380px; */
  /* border: 1px solid black; */
  border-radius: 10px;
  background-color: white;
  padding: 2% 4%;
}

.Testimonialspassageone {

  /* display: flex;
    justify-content: center;
    align-items: center; */
  Font-family: Poppins;
  Font-style: Regular;
  Font-size: 18px;
  Line-height: 36px;
  color: #3B4667;
  text-align: start;
}

.TestimonialspassageTwo {
  Font-family: Poppins;
  Font-style: Regular;
  text-align: right;
  /* padding-top: 25px; */
  Font-size: 26px;
  Line-height: 40px;
  color: #1F2C51;
  padding-bottom: 10px;
  padding-left: 10px;
}

.Testimonialsimg {
  width: 40px;
  height: 60px;
  position: relative;
  /* top: 34px; */
  /* left: 140px */
  top: -60px;
  left: -64px;

}

/* whyLokahBlockStart */
@media(min-width:768px) and (max-width:2560px) {
  .mobilesteps {
    display: none;
  }

  .lapysteps {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lapysteps img {
    width: 100%;

  }
}

@media(min-width:320px) and (max-width:767px) {
  .lapysteps {
    display: none;
  }

  .mobilesteps {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobilesteps img {
    width: 100%;

  }
}
