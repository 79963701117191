* {
  font-family: 'Poppins';
}

.lokahhoMainDiv {
  width: 100%;
  background-image: url('../../Assets/Bubble.png'), url('../../Assets/Bubble.png');
  background-position: top left;
  background-size: 50vw 40vw, 49vw 39vw;
  background-repeat: no-repeat;
}

.lokahhomefirstDiv {
  width: 100%;
  height: 20vw;
  
}
.mainbg{
  width: 100%;
  background: linear-gradient(90deg, #9BD3E8 0%, rgba(141, 202, 225, 0.3) 98.83%); 
}

.lokahhomeSubdiv {
  width: 90%;
  
}

.lbold {
  font-weight: 800 !important;
}

.headingTexts {
  width: 99%;
  text-align: start;
}

.lokahfirstImgDiv {
  width: 100%;
}

.mainimg {
  width: 45vw;
  height: 50vw;
  margin-top: 5vw;
}

.startYourJourney {
  font-size: 3vw;
  font-weight: 600;
  width: 100%;
  padding-top: 8vw;
  padding-bottom: 1vw;
  color: #1F2C51;
  text-align: start;
}

.morethanDiv {
  width: 100%;
  height: 100%;
  font-size: 1.2VW;
  letter-spacing: 1;
}

.lokahDiscription {
  width: 70%;
  font-size: 1.5vw;
  margin-top: 2%;
  text-align: start;
  color: #003F66 !important;
}

.homeAchivementsMainDiv {
  width: 100%;
}

.homeAchivementsSubDiv {
  width: 88.9%;
}

.achivementsText {
  font-size: 1.7vw;
  padding-top: 3vw;
  padding-bottom: 2vw;
  color: #003F66;

}

.MethodologyText {
  font-size: 2vw;
  font-weight: 600;
  color: #003F66;
  padding-bottom: 2vw;
}

.achivementDescriptionDiv {
  width: 100%;
  font-size: 1.4vw;
  text-align: start;
}

.stepOneDiv {
  width: 100%;
}

.steptwoDiv {
  width: 100%;
}

.steponeImg {
  height: 25vw;
  width: 43.5vw;
}

.steptwoImg {
  height: 23vw;
  width: 42vw;
  margin-top: 2.9%;
}

.stepsMainDiv {
  padding-top: 3vw;
  padding-bottom: 3vw;
  width: 100%;
}

.meetLokahMaindiv {
  width: 100%;
}

.meetlokahTextDiv {
  width: 90%;
  text-align: center;
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: 2.5vw;
  font-weight: 600;
  color: rgba(0, 63, 102, 1);
}

.meetlokahFlexDiv {
  width: 100%;
}

.meetLokahSubDivOne {
  width: 100%;
}

.meetLokahSubDivTwo {
  width: 100%;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.meetLokahSubDivOneChildDiv {
  width: 80%;
}

.theworldLokah {
  font-size: 2vw;
  font-weight: 700;
  padding-top: 5vw;
  color: #003F66;
}

.theWordLokahDescription {
  width: 95%;
  font-size: 1.2vw;
  padding-top: 2vw;
  letter-spacing: 1px;
  text-align: start;
}

.meetuplokahImg {
  height: 26vw;
  width: 40vw;
}

.ourServiceMainDiv {
  width: 100%;
  background: linear-gradient(90deg, #9BD3E8 0%, rgba(141, 202, 225, 0.3) 98.83%);
  background-size: 100% 65vw;
  background-repeat: no-repeat;
}

.ourServiceSubDiv {
  width: 90%;
  height: fit-content;
  padding-top: 2vw;
  padding-bottom: 2vw;
  text-align: center;
  font-size: 2vw;
  font-weight: 600;
  color: #003F66;
}

.homeCardsDiv {
  width: 80%;
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.cardsDivHomepage {
  background-color: #FFFFFF;
  height: 30vw;
  width: 22vw;
  box-shadow: 0px 20px 40px rgba(8, 150, 204, 0.2);
  border-radius: 0.3vw;
  text-align: start;
  gap: 3%;
  align-items: center;
}

.homecardImgOneDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url('../../Assets/rocketLaunch.png');
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
  align-items: center;
}

.homecardImgTwoDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url('../../Assets/closeupBusiness.png');
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homecardImgThreeDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url('../../Assets/highwayView.png');
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homecardImgFourDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url('../../Assets/UKwork.png');
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homecardImgFiveDiv {
  width: 18vw;
  height: 14vw;
  margin-top: 5%;
  background-image: url('../../Assets/student.png');
  background-size: 100% 100%;
  background-position: center;
  border-radius: 0.3vw;
}

.homeCardsSecondDiv {
  width: 60%;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.cardsTextDiv {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.7vw;
  line-height: 125%;
  text-align: start;
  color: #232323;
}

.cardParaDiv {
  font-family: 'Poppins';
  font-style: normal;
  /* font-weight: 200; */
  font-size: 18px;
  line-height: 22px;
  text-align: start;
  color: #232323;
}

.cardButtonDiv button {
  border: none;
  outline: none;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  background-color: white;
  color: #424242;

}

.cardstextmaindiv {
  width: 80%;
}

.cardsTextDiv {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.7vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  text-align: start;
  color: #232323;
}

.cardParaDiv {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-size: 1vw;
  line-height: 1.5vw;
  text-align: start;
  color: #232323;
}

.getstarted {
  font-size: 1.2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.getstarted button {
  border: none;
  background-color: #FFFFFF;
}

.GsBtn span {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

  color: #424242;
}

@media screen and (min-device-width: 1px) and (max-device-width: 767px) {
  .lokahhoMainDiv {
    width: 100%;
    background-image: url('../../Assets/Bubble.png'), url('../../Assets/Bubble.png');
    background-position: top left;
    background-size: 85vw 65vw, 83vw 63vw;
    background-repeat: no-repeat;
  }

  .lokahhomefirstDiv {
    height: fit-content;
    padding-top: 3vw;
  }

  .startYourJourney {
    font-size: 5vw;
    width: 100%;
    /* padding-top: 20vw; */
  }

  .morethanDiv {
    font-size: 2.3vw;

  }

  .lokahDiscription {
    font-size: 2.7vw;
    width: 40%;
    white-space: nowrap;

  }

  .lokahDiscription p {
    margin-bottom: 3% !important;
  }

  .mainimg {
    width: 65vw;
    height: 70vw;
    margin-top: 0%;
  }

  .achivementsText {
    font-size: 2.7vw;
  }

  .MethodologyText {
    font-size: 3vw;
  }

  .achivementDescriptionDiv {
    font-size: 2.4vw;
    text-align: start;
  }

  .steponeImg {
    height: 45vw;
    width: 68.5vw;
  }

  .steptwoImg {
    height: 30vw;
    width: 50vw;
    margin-top: 0;
  }

  .meetlokahTextDiv {
    font-size: 3.5vw;
  }

  .theWordLokahDescription {
    font-size: 2.5vw;
  }

  .theworldLokah {
    font-size: 3vw;
  }

  .meetuplokahImg {
    height: 50vw;
    width: 85vw;
  }

  .homeCardsDiv {
    width: 90%;
  }

  .cardsDivHomepage {
    margin-top: 10%;
    width: 90vw;
    height: 130vw;
    border-radius: 1.5vw;
    gap: 3%;

  }

  .homecardImgOneDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgTwoDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgThreeDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgFourDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .homecardImgFiveDiv {
    width: 90%;
    height: 55vw;
    border-radius: 1.5vw;
  }

  .ourServiceSubDiv {
    font-size: 4.5vw;
  }

  .cardsTextDiv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 7vw;
    line-height: 125%;
    text-align: start;
    color: #232323;
  }

  .cardParaDiv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 5vw;
    line-height: 20px;
    text-align: start;
    /* margin-left: 5%; */
    color: #232323;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .Testimonialspassageone {
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 10px !important;
    Line-height: 15px !important;
    color: #3B4667;

  }

  .TestimonialspassageTwo {
    Font-family: Poppins;
    Font-style: Regular;
    text-align: center !important;
    /* padding-top: 25px; */
    Font-size: 15px !important;
    Line-height: 40px;
    color: #1F2C51;
    /* padding-bottom: 10px; */
    /* padding-left: 10px; */
    white-space: nowrap;
  }

  .ourServiceMainDiv {
    background-size: 100% 91%;
  }

  .cardsTextDiv {
    font-size: 6vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }

  .cardParaDiv {
    font-size: 3.5vw;
    line-height: 6vw;
    text-align: left;
  }

  .getstarted {
    font-size: 4vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
  }
}

/* whyLokahBlockStart */
.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.lokahBlock {
  margin-bottom: 60px;
}

.whyLokahBlock {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 4%;
  column-gap: 4%;
  padding-right: 4%;
  /* justify-content: space-evenly; */


}

.lokahBlockimg {
  height: 26vw;
  width: 40vw;

}

.lokahBlock h1 {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 40px;
  Font-family: Poppins;
  Font-style: SemiBold;
  Font-size: 48px;
  Line-height: 60px;
  color: #003F66;
}

.whyLokahRightBlock {
  /* padding-top: 30px; */
  Font-family: Poppins;
  Font-style: Regular;
  Font-size: 18px;
  Line-height: 40px;
  color: #232323;
  text-align: start;
}

.lokahBlockPassage {
  padding-top: 40px;
  padding-left: 4%;
  padding-right: 4%;
  text-align: justify;
}

.lokahBlockPassageone {
  Font-family: Poppins;
  Font-style: Regular;
  Font-size: 18px;
  Line-height: 30px;
}

@media(min-width:1024px) and (max-width:1170px) {
  .lokahBlockimg {
    height: 30vw;
    width: 46vw;
  }

  .whyLokahRightBlock {
    padding-top: 20px;
  }
}

@media(min-width:920px) and (max-width:1023px) {
  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 16px;
    Line-height: 28px;
    color: #232323;
  }
}

@media(min-width:768px) and (max-width:920px) {

  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 15px;
    Line-height: 25px;
    color: #232323;
  }

}

@media(min-width:424px) and (max-width:767px) {

  .whyLokahBlock {

    display: flex;
    flex-direction: column;
    /* align-items: ; */
    /* padding-left: 4%; */
    /* row-gap: 4%; */
    /* padding-right: 4%; */
  }

  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 20px;
    Line-height: 40px;
    color: #232323;
  }

  .lokahBlockimg {
    height: 50vw;
    width: 90vw;
    /* margin-bottom: 25px; */
  }
}

@media(min-width:320px) and (max-width:426px) {

  .lokahBlockrevrse {
    display: flex;
    flex-direction: column-reverse;
  }

  .lokahBlock h1 {
    padding-bottom: 0px;
  }

  .whyLokahBlock {

    display: flex;
    flex-direction: column;
    /* padding-top: 20px; */
    /* align-items: ; */
    padding-left: 6%;
    /* row-gap: 4%; */
    padding-right: 6%;
  }

  .whyLokahRightBlock {
    padding-top: 15px;
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 10px;
    Line-height: 25px;
    color: #232323;
    padding-left: 6%;
    padding-right: 6%;


  }

  .lokahBlockimg {
    height: 50vw;
    width: 90vw;
    /* margin-bottom: 25px; */
    padding-right: 4%;
  }

  .lokahBlockPassageone {
    Font-family: Poppins;
    Font-style: Regular;
    Font-size: 10px;
    Line-height: 20px;
  }

  .lokahBlockPassage {
    padding-top: 8px;
    padding-left: 8%;
    padding-right: 8%;
    text-align: justify;

  }
}

.TestimonialsBlock {
  display: flex;
  /* flex-direction: column; */
}

.Testimonials {
  background: rgba(155, 211, 232, 0.3);
  padding-bottom: 60px;
}


.TestimonialsLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

/* .TestimonialsLefticon{
    width:80px;
  }
  .TestimonialsRighticon{
    width:40px;
  } */
.TestimonialsRight {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TestimonialsHeading {
  display: flex;
  justify-content: center;
  Font-family: Poppins;
  Font-style: SemiBold;
  Font-size: 48px;
  Line-height: 60px;
  color: #003F66;
  padding-bottom: 60px;
  padding-top: 40px;
}

.Testimonialspassage {
  width: 60%;
  /* height: 380px; */
  /* border: 1px solid black; */
  border-radius: 10px;
  background-color: white;
  padding: 2% 4%;
}

.Testimonialspassageone {

  /* display: flex;
    justify-content: center;
    align-items: center; */
  Font-family: Poppins;
  Font-style: Regular;
  Font-size: 18px;
  Line-height: 36px;
  color: #3B4667;
  text-align: start;
}

.TestimonialspassageTwo {
  Font-family: Poppins;
  Font-style: Regular;
  text-align: right;
  /* padding-top: 25px; */
  Font-size: 26px;
  Line-height: 40px;
  color: #1F2C51;
  padding-bottom: 10px;
  padding-left: 10px;
}

.Testimonialsimg {
  width: 40px;
  height: 60px;
  position: relative;
  /* top: 34px; */
  /* left: 140px */
  top: -60px;
  left: -64px;

}

/* whyLokahBlockStart */
@media(min-width:768px) and (max-width:2560px) {
  .mobilesteps {
    display: none;
  }

  .lapysteps {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lapysteps img {
    width: 100%;

  }
}

@media(min-width:320px) and (max-width:767px) {
  .lapysteps {
    display: none;
  }

  .mobilesteps {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobilesteps img {
    width: 100%;

  }
}