@media screen and (max-width: 767px) and (min-width: 100px) {
    .MainDivUKW {
        height: 70vw !important;

    }

    .UKWtextOne {
        font-size: 5vw;
    }

    .UKWimgtransDiv {
        height: 30vw;
        width: 40vw;
        background-color: white;
    }

    .UKWTextOneSubDiv h1 {
        font-size: 5vw !important;
    }

    .UKWTextOneDiv p {
        font-size: 3.2vw !important;
    }

    .documenttextMainDiv {
        width: 100%;
    }

    .documentsRequiredDiv {
        width: 100%;
        margin-top: 5%;
        background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%) !important;
    }

    .UKWdocumentsCardSubdiv span {
        font-size: 3.5vw !important;
    }

    .UKWdocumentsCardSubdiv p {
        font-size: 2.5vw !important;
        margin-bottom: 0%;
    }

    .UKWdocumentsRequiredTextDiv span {
        font-size: 3.5vw !important;
    }

    .UKWdocumentsRequiredTextDiv p {
        font-size: 2.5vw !important;
    }

    .UKWdocumentTextwrapTwoDiv {
        margin-top: 10%;
    }

    .officeAddressMainDiv {
        width: 50%;
    }

    .mapImageDiv {
        background-size: 100% 80%;
    }

    .contactUstextdiv span {
        font-size: 5vw;

    }

    .UKWDocReqHead {
        font-size: 4vw !important;
    }

    .tickiconsCls {
        height: 4vw !important;
        width: 4vw !important;
    }

    .mandateText {
        font-size: 2.6vw !important;
    }

    /* contactus */
    .contactusMapmainDiv {
        height: 70vw !important;
    }

    .contactusGirlmainDiv {
        margin-top: 10%;
        height: 90vw !important;
    }

    .callanytime {
        font-size: 2vw;
    }

    .contactusCardTwoDiv {
        width: 50% !important;
        height: 26vw !important;
        /* background-color: yellow !important; */

    }

    .callAnytimeparagraph {
        margin-top: 0% !important;
    }

    .callanytime {
        margin-top: 5%;
        height: 3vw !important;
    }

    .callAnytimeText {
        margin-top: 5%;
        font-size: 3vw !important;
    }


}

.UKWmainDiv {
    height: 100%;
    /* background: linear-gradient(90deg, #E1F3FA 0%, rgba(225, 243, 250, 0.15) 98.83%); */
}

.MainDivUKW {
    height: 47vw;
    width: 100%;
    background-image: url('../../Assets/UKwork.png');
    background-position: center;
    background-size: 100% 100%;
}

.UKWTexteTransimg {
    width: 90%;
}


.UKWtextOne {
    font-style: normal;
    font-weight: 700;
    font-size: 4.5vw;
    color: #FFFFFF;

}

.UKWimgtransDiv {
    height: 20vw;
    width: 30vw;
    background-color: white;
}

.UKWTextSecondDiv {
    width: 90%;
    margin-top: 5%;
}

.UKWTextOneDiv {
    text-align: left;
    width: 100%;
}

.UKWImageTwoDiv {
    width: 100%;
}

.UKWTextOneSubDiv h1 {
    font-size: 3vw;
    color: #003F66;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
}

.UKWTextOneDiv p {
    font-size: 1.3vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;

}

.UKWformImgSize {
    width: 90%;
}

.UKWDocumentsRequired {
    background: linear-gradient(90deg, #BBE0EF 0%, #E4F3F8 98.83%);
    width: 100%;
    height: 100%;
    padding-bottom: 2%;
}

.UKWDocumentReqMabdateDiv {
    width: 90%;
    height: fit-content;
    margin-top: 5%;
    text-align: start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.tickiconsCls {
    height: 2vw;
    width: 2vw;
}

.mandateText {
    font-size: 1.5vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.UKWdocumentsDataMainDIv {
    width: 100%;

}

.UKWdocumentTextMAinwarp {
    width: 90%;
    /* background-color: #082A36; */
}

.UKWdocumentTextwraponeDiv {
    width: 100%;
    /* background-color: beige; */
}

.UKWdocumentTextwrapTwoDiv {
    width: 100%;
    /* background-color: blue; */
}

.UKWdocumentTextwraponeSubDivone {
    /* background-color: #CBCBCB; */
    width: 100%;
    height: fit-content;
}

.UKWdocumentTextwraponeSubDivtwo {
    width: 100%;
    /* background-color: #082A36; */
    height: 100%;
}

.papperworkimg {
    height: 30vw;
}

.UKWdocumentsCardOne {
    background: #FFFFFF;
    height: fit-content;
    width: 80%;
    border-radius: 50vw;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    border-radius: 6px;
}

.UKWdocumentsCardSubdiv {
    width: 90%;
    height: 90%;
    text-align: left;
}

.UKWdocumentsRequiredTextDiv {
    width: 110%;
    height: 90%;
}

.UKWdocumentsCardSubdiv span {
    font-size: 1.8vw;
    /* white-space: nowrap; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start !important;
}

.paperwork {
    font-size: 1.2vw !important;
    white-space: nowrap;
    font-weight: 500;
}

.UKWdocumentsCardSubdiv p {
    font-size: 1vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start !important;
}

.UKWdocumentsRequiredTextDiv span {
    font-size: 1.8vw;
    font-family: 'Poppins';
    font-style: normal;
    white-space: nowrap;
    font-weight: 400;
}

.UKWdocumentsRequiredTextDiv p {
    font-size: 1vw;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: start;
    margin-left: 5px;
}

.UKWDocReqHead {
    font-size: 3vw;
    color: #003F66;
    font-weight: 600;
    font-family: 'Poppins';
    font-style: normal;

}

.UKWDocumentsRequired {
    margin-top: 5%;
}


/* contact us css */

.contactusMainDiv {
    width: 100%;
    position: relative;
}

.contactusTextDiv {
    width: 100%;
    font-size: 3vw;
    font-weight: 600;
    height: 10vw;
    color: #003F66;
    font-family: 'Poppins';
    font-style: normal;

}

.contactusSubDiv {
    width: 90%;
    /* background-color: black; */
}

.contactusGirlmainDiv {
    width: 100%;
    background-image: url('../../Assets/girlimg.png');
    background-size: 100% 100%;
    background-position: center;
    height: 50vw;
}

.contactusMapmainDiv{
    width: 100%;
    height: 40vw;
    margin-left: 2%;
}

.contactusCardsMainDiv {
    position: absolute;
    /* background-color: red; */
    width: 70vw;
    left: 10;
    bottom: 0;
}

.contactusCardoneDiv {
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);
}

.contactusCardoneSubDiv a {
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.171875vw;
    line-height: 1.953125vw;
    color: #0896CC;
    margin-top: 6%;

}

.contactusCardTwoDiv {
    background-color: white;
    width: 90%;
    height: 23vw;
    border-radius: 10px;
    border: 1px solid #CBCBCB;
    box-shadow: 0px 15px 35px rgba(34, 79, 94, 0.06);

}

.contactusCardoneSubDiv {
    width: 90%;

}

.callanytime {
    margin-top: 10%;
    height: 3vw;
}

.callAnytimeText {
    /* margin-top: 10%; */
    font-size: 2vw;
    font-family: 'Poppins';
    font-style: normal;
    color: #082A36 !important;
}

.callAnytimeparagraph {
    /* margin-top: 10%; */
    font-size: 1.2vw;
    text-align: left;
    margin-bottom: 0%;
    font-family: 'Poppins';
    font-style: normal;
}


/* Faq CSS */

.documentsBlocl {

    width: 100%;
    background-image: url('../../Assets/bg.png');
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    


}

.blockTwoLeft {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2%;
    align-items: start;
}

.Duration {
    width: 80%;
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.documentsRequired {
    box-shadow: 0px 0px 19px rgba(8, 150, 204, 0.09);
    background-color: #FFFFFF;
    border-radius: 6px;
}



.questionsBlock {

    /* border-bottom: solid #0896CC; */

}

h2 {
    padding-top: 40px;
    padding-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800 !important;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    /* margin-bottom: 30px; */
}

.tab {
    display: flex;
    justify-content: center;
}

.tab div {
    display: none;

}

.tab div:target {
    display: block;
}

.H1B {

    border-radius: 10px;
    list-style-type: none;
    width: 50%;
    background: #FFFFFF;


}

.tab ul {
    padding-top: 8px;
    padding-bottom: 8px;
}

.question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    text-align: left;
    color: #343434;
    padding-top: 12px;
    padding-bottom: 12px;

}

.tab ul a {
    text-decoration: none;
}

.tab ul li a:hover {
    color: black;
}

.tab ul li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tab p {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-right: 20px;

}

.subPoints li {
    text-align: left !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    padding-top: 5px;
    padding-right: 0px !important;

}

@media(min-width:1024px) and (max-width:1439px) {
    .tab ul {
        width: 65%;
    }

}

@media(min-width:768px) and (max-width:1023px) {
    .tab ul {
        width: 80%;
    }

}

@media(min-width:320px) and (max-width:767px) {
    .tab ul {
        width: 90%;
    }

    h2 {
        padding-top: 30px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 120%;
        color: #FFFFFF;
        text-align: center;
        white-space: nowrap;
    }
}