/* .headerMain {
    background-color: white;

} */

/* .logoimg {
    
    height: 70px;
    margin-left: 2%;
} */

/* .navbar-collapse {

    width: 100%;
} */

.nav-link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800 !important;
    font-size: 20px;
    white-space: nowrap;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: #272727 !important;

    /* margin-right: 16px; */

}

.icons {
    border: 1px solid #54CDE7;
    border-radius: 4px;
    padding-top: 15px;
    padding-bottom: 6px;
    box-shadow: 0px 4px 4px 1px rgba(18, 139, 204, 0.2);

    

}
/* .navbar-nav > .active > a {
    background: linear-gradient(199.06deg, #54CDE7 2.74%, #008AE4 95.32%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;

  } */
  /* .nav-item > a:hover {
    background: linear-gradient(199.06deg, #54CDE7 2.74%, #008AE4 95.32%) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
background-clip: text !important;
text-fill-color: transparent !important;

  } */

/* @media (min-width:'1280px') and (max-width:'1440px'){
    .nav-link {
       
        margin-right: 12px;
    
    }

} */







.navigation {
    background-color: #eff1f2;
    /* padding: 2.5925925926vh 0px; */
    /* padding-right: 5vw; */
    /*100px;*/
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
}
/* #navbarSupportedContent {
    transition: height 1.5s ease;
} */
/* .navigation .logo {
    padding-left: 6vw;

} */

.navbtn {
    background: linear-gradient(258deg, #3473b3, #761acf) !important;
}

.hamburger {
    /* background-color: #00151d !important; */
    border: none;
}

.mymenu {

    /* border-color: !important; */
    height: 0.9vw;
    background: linear-gradient(258deg, #3473b3, #761acf) !important;
    width:7vw;
    display: block;
    margin-top:1vw;
    border-radius: 5px;
}
/* .cryptoLogo{
    height: 4vw !important;
    width: 9.8vw !important;
    align-items: center !important;
    float: left;
    
} */

.hamburger span:first-child {
    margin-top: 0px;
}

.logo img {
    /* width: 35px; */
    height: 75px;
    margin-left: 5%;
    /* width: 50%; */
}
.ulist{
    align-items: center;
    text-align: center;
    gap: 7%;
    white-space: nowrap;
}

.navigation li {
    /* margin-right: 3.208333vw; */
    /*100px;*/
    align-self: center;
}

.navigation li a {
    padding: 0px 0px 0px 0px;
    /* font-size: max(0.9375vw, 15px); */
    font-size: 1.3vw;
   color: #272727;
   text-decoration: none;

    
    
}

.navigation li a.active {
    background: linear-gradient(199.06deg, #54CDE7 2.74%, #008AE4 95.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.navigation li:last-child {
    margin-right: 0px;
    /* background: linear-gradient(258deg, #3473b3, #761acf); */
    border-radius: 8px;
    color: #131313;
}

/* .navigation li:last-child a {
    -webkit-text-fill-color: #ffffff;
    color:#ffffff !important;
} */
.navigation li a.active{
    -webkit-text-fill-color: #ffffff;
    /* color: #ffffff !important; */
}

@media screen and (max-width:920px) {
    .navigation li {
        margin-right: 0px;
        margin-top: 10px;
    }
    .navigation li:last-child {
        display: none;
    }

    .navigation li a {
        font-size: 18px;
    }
}


.myitem {
    background-color: red;
}

@media screen and (max-width: 767px)
{
    .cryptoLogo{
        height: 15vw !important;
        width: 40vw !important;
    }
    
}




